<template>
	<div>
		<div class="container">
			<div class="handle-box">
				<el-select size="small" v-if="isAdmin" @change="agentChange" v-model="query.agentId" filterable
					clearable placeholder="代理商" class="handle-select mr10" style="width: 260px;">
					<el-option v-for="item in agents" :key="item.value" :label="item.label"
						:value="item.value"></el-option>
				</el-select>
				<el-select size="small" v-if="isSubAgent" v-model="query.subAgentId" @change="getfetchDefaultCompanies"
					filterable clearable placeholder="子代理" class="handle-select mr10" style="width: 260px;">
					<el-option v-for="item in subAgentList" :key="item.id" :label="item.name"
						:value="item.id"></el-option>
				</el-select>
				<el-select size="small" style="width: 260px" v-model="query.companyId" filterable clearable
					placeholder="企业" class="handle-select mr10">
					<el-option v-for="item in companys" :key="item.value" :label="item.label"
						:value="item.value"></el-option>
				</el-select>
				<el-button size="small" type="primary" :class="[theme]" icon="el-icon-search"
					@click="handleSearch">搜索</el-button>
				<el-button size="small" v-if="canAddCompany" type="primary" :class="[theme]" class="handle-add mr100"
					@click="showForm">新增字段</el-button>
			</div>
			<el-table :data="tableData" border class="table" ref="multipleTable" header-cell-class-name="table-header"
				:row-style="{ height: '20px' }" :cell-style="{ padding: '6px' }"
				:header-cell-style="{ 'text-align': 'center' }" :row-class-name="tableRowClassName">
				<el-table-column prop="chineseType" width="250" label="字段类型" align="center"></el-table-column>
				<el-table-column prop="label" width="250" label="字段名称" align="center"></el-table-column>
				<el-table-column prop="options" label="字段内容" align="center">
					<template #default="scope">
						<el-tag style="margin-right: 10px;"
							v-for="(item, index) in scope.row.options">{{ item }}</el-tag>
					</template>
				</el-table-column>
				<el-table-column prop="enable" width="250" label="CRM客户启用" align="center">
					<template #default="scope">
						<el-checkbox-group v-model="scope.row.enable"
							@click="$event => SkipProgress($event, scope.row)">
							<el-checkbox v-model="scope.row.enable">CRM启用</el-checkbox>
						</el-checkbox-group>
					</template>
				</el-table-column>
				<el-table-column prop="customerEnable" width="250" label="线索启用" align="center">
					<template #default="scope">
						<div
							v-if="scope.row.type == 'radio-drop-down-box' || scope.row.type == 'multi-select-drop-down-box'">
							<el-checkbox-group v-model="scope.row.customerEnable"
								@click="$event => getcustomerEnable($event, scope.row)">
								<el-checkbox v-model="scope.row.customerEnable" disabled>启用</el-checkbox>
							</el-checkbox-group>
						</div>
						<div v-else>
							<el-checkbox-group v-model="scope.row.customerEnable"
								@click="$event => getcustomerEnable($event, scope.row)">
								<el-checkbox v-model="scope.row.customerEnable">启用</el-checkbox>
							</el-checkbox-group>
						</div>
					</template>
				</el-table-column>
				<el-table-column label="操作" width="300" align="center">
					<template #default="scope">
						<el-button size="small" :disabled="canEditCompany" type="text" icon="el-icon-edit"
							@click="handleEdit(scope.$index, scope.row)">编辑标签名</el-button>
						<el-button size="small" :disabled="canEditCompany" type="text" icon="el-icon-edit"
							@click="handleEdit2(scope.$index, scope.row)"
							v-if="scope.row.chineseType=='单选框'||scope.row.chineseType=='多选框'">编辑选项</el-button>
						<el-button size="small" type="text" icon="el-icon-delete" style="color: #f56c6c;"
							@click="handleDelete(scope.$index, scope.row)">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<el-dialog title="添加标签" v-model="addVisible" width="500px" @close="closeDialog('form')"
			:close-on-click-modal="false">
			<el-form :model="form" :rules="rules" ref="form" label-width="100px">
				<el-form-item label="标签类型">
					<el-select size="small" v-model="form.chineseType" placeholder="请选择标签类型" style="width:100%">
						<el-option v-for="item in protectionLevel" :key="item.value" :label="item.label"
							:value="item.value"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="标签名称" prop="label"><el-input size="small"
						v-model="form.label"></el-input></el-form-item>
				<el-form-item label="标签选项" prop="options"
					v-if="form.chineseType === 'radio-drop-down-box' || form.chineseType === 'multi-select-drop-down-box'">
					<el-tag style="margin-right: 10px;" :key="tag" v-for="tag in form.options" closable
						:disable-transitions="false" @close="handleClose(tag)">{{ tag }}</el-tag>
					<el-input size="small" class="input-new-tag" v-if="inputVisible" v-model="inputValue"
						ref="saveTagInput" @keyup.enter.native="handleInputConfirm"
						@blur="handleInputConfirm"></el-input>
					<el-button size="small" v-else class="button-new-tag" @click="showInput">+标签名</el-button>
				</el-form-item>
				<el-form-item class="dialog-footer" style="display: flex;flex-direction: row;justify-content: end;">
					<el-button size="small" @click="closeDialog">取消</el-button>
					<el-button size="small" type="primary" :class="[theme]" @click="addCustomize('form')">确定</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
		<el-dialog title="编辑标签名" v-model="editVisible" width="500px" @close="closeDialog('form')"
			:close-on-click-modal="false">
			<el-form :model="form" :rules="rules" ref="form" label-width="100px">
				<el-form-item label="标签类型" prop="chineseType">
					<!-- <el-input size="small" v-model="form.chineseType" disabled></el-input> -->
					<el-select size="small" v-model="form.chineseType" placeholder="请选择标签类型" style="width:100%"
						disabled>
						<el-option v-for="item in protectionLevel" :key="item.value" :label="item.label"
							:value="item.value"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="标签名称" prop="label"><el-input size="small"
						v-model="form.label"></el-input></el-form-item>

				<el-form-item class="dialog-footer" style="display: flex;flex-direction: row;justify-content: end;">
					<el-button size="small" @click="closeDialog">取消</el-button>
					<el-button size="small" type="primary" :class="[theme]"
						@click="updateCustomizeName('form')">确定</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
		<el-dialog title="编辑选项" v-model="editVisible2" width="500px" @close="closeDialog('form')"
			:close-on-click-modal="false">
			<el-form :model="form" :rules="rules" ref="form" label-width="100px">
				<el-form-item label="标签名称" prop="label"><el-input size="small" v-model="form.label"
						disabled></el-input></el-form-item>
				<el-form-item label="标签类型" prop="chineseType">
					<el-select size="small" v-model="form.chineseType" placeholder="请选择标签类型" style="width:100%"
						disabled>
						<el-option v-for="item in protectionLevel" :key="item.value" :label="item.label"
							:value="item.value"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="标签选项" prop="options">
					<el-tag style="margin-right: 10px;" :key="tag" v-for="tag in form.options" closable
						:disable-transitions="false" @close="handleClose(tag)">{{ tag }}</el-tag>
					<el-input size="small" class="input-new-tag" v-if="inputVisible" v-model="inputValue"
						ref="saveTagInput" @keyup.enter.native="handleInputConfirm"
						@blur="handleInputConfirm"></el-input>
					<el-button class="button-new-tag" size="small" @click="showInput">+标签名</el-button>
				</el-form-item>
				<el-form-item class="dialog-footer" style="display: flex;flex-direction: row;justify-content: end;">
					<el-button size="small" @click="closeDialog">取消</el-button>
					<el-button size="small" type="primary" :class="[theme]" @click="updateCustomizeContent('form')"
						v-if="form.chineseType === '单选框' || form.chineseType === '多选框'">确定</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
	</div>
</template>

<script>
	import {
		list,
		delCompany,
		fetchCompanyById,
		fetchCompanyByAgentId,
		uptoken,
		uptoken1
	} from '../api/companyIndex.js';
	import {
		fetchAgent
	} from '../api/agentIndex';
	import {
		fetchDefaultCompanies
	} from '../api/companyIndex';
	import {
		getListCustomize,
		updateCustomize,
		addCustomize,
		deleteCustomize,
		updateCustomerCustomize,
		crmCustomizeEnable,
		updateCustomizeName,
		updateCustomizeContent
	} from '../api/tag.js';
	import {
		querySubAgent
	} from '../api/serialIndex.js';
	export default {
		name: 'customTag',
		data() {
			return {
				theme: localStorage.getItem('theme'),
				query: {
					companyId: '',
					userId: localStorage.getItem('user'),
					subAgentId: ''
				},
				tableData: [],
				companys: [],
				agents: [],
				addVisible: false,
				editVisible: false,
				editVisible2: false,
				canAddCompany: true,
				isAdmin: false,
				isSubAgent: false,
				pageTotal: 0,
				loading1: false,
				form: {
					chineseType: '',
					label: '',
					options: [],
					companyId: ''
				},
				oldLabel: '',
				loading: false,
				inputVisible: false,
				inputValue: '',
				protectionLevel: [{
						value: 'multiline-text',
						label: '文本'
					},
					{
						value: 'date',
						label: '日期'
					},
					{
						value: 'time',
						label: '时间'
					},
					{
						value: 'radio-drop-down-box',
						label: '单选下拉'
					},
					{
						value: 'multi-select-drop-down-box',
						label: '多选'
					}
				]
			};
		},

		created() {
			let role = localStorage.getItem('ms_role');
			this.subAgent = localStorage.getItem('subAgent');
			console.log(role);
			if (role === 'normal') {
				this.canAddCompany = false;
			}
			if (role === 'admin') {
				this.isAdmin = true;
			}
			if (role === 'agent') {
				this.isNotAdmin = false;
				this.isAgent = true;
				if (this.subAgent == 'true') {
					this.isSubAgent = true;
					this.getquerySubAgent();
				} else {
					this.isSubAgent = false;
				}
			}
			if (role === 'subAgent') {
				this.isAgent = true;
			}
			this.init();
			// this.getuptoken()
		},
		methods: {
			getfetchDefaultCompanies() {
				this.query.companyId = '';
				let data = {
					currentUserId: localStorage.getItem('user'),
					subAgentId: this.query.subAgentId
				};
				fetchDefaultCompanies(data).then(res => {
					if (res.code == 200) {
						this.companys = res.data;
						if (res.data.length > 0) {
							this.query.companyId = this.companys[0].value;
							this.query.pageIndex = 1;
							this.getData();
						}
					} else {
						this.$message.error(res.message);
					}
				});
			},
			getquerySubAgent() {
				let data = {
					agentId: this.query.agentId,
					userId: localStorage.getItem('user')
				};
				querySubAgent(data).then(res => {
					if (res.code == 200) {
						this.subAgentList = res.data;
					} else {
						this.$message.error(res.message);
					}
				});
			},
			handleClose(tag) {
				this.form.options.splice(this.form.options.indexOf(tag), 1);
			},

			showInput() {
				this.inputVisible = true;
				this.$nextTick(_ => {
					this.$refs.saveTagInput.$refs.input.focus();
				});
			},

			handleInputConfirm() {
				let inputValue = this.inputValue;
				if (inputValue) {
					this.form.options.push(inputValue);
				}
				this.inputVisible = false;
				this.inputValue = '';
			},

			tableRowClassName({
				rowIndex
			}) {
				if ((rowIndex + 1) % 2 === 0) {
					return 'oddRow';
				}
				return 'evenRow';
			},
			init() {
				fetchAgent(localStorage.getItem('user')).then(res => {
					if (res.code == 200) {
						this.agents = res.data;
					} else {
						this.$message.error(res.message);
					}
				});

				if (this.subAgent == true) {
					this.isSubAgent = true;
					this.getquerySubAgent();
				}
				this.getfetchDefaultCompanies();
			},
			agentChange(agentId) {
				this.query.companyId = '';
				this.query.userId = '';
				this.form.companyId = '';
				this.form.userId = '';
				if (agentId) {
					let data = {
						subAgent: JSON.parse(localStorage.getItem('subAgent').toLowerCase()),
						agentId: agentId
					};
					fetchCompanyByAgentId(data).then(res => {
						this.companys = res.data;
					});
				}
			},

			SkipProgress(e, row) {
				if (e.target.tagName !== 'INPUT') {
					return;
				} else {
					this.form = row;
					if (row.enable) {
						this.form.enable = false;
					} else {
						this.form.enable = true;
					}
					let data = {
						companyId: this.query.companyId,
						uid: this.form.uid,
						enable: this.form.enable
					}
					crmCustomizeEnable(data).then(res => {
						if (res.code == 200) {
							this.$message.success('修改成功');
							this.getData();
							// this.editVisible = false;
						} else {
							this.$message.error(res.message);
						}
					});
				}
			},
			getcustomerEnable(e, row) {
				if (e.target.tagName !== 'INPUT') {
					return;
				} else {
					if (row.customerEnable) {
						this.form.uid = row.uid;
						this.form.customerEnable = false;
						this.getupdateCustomerCustomize();
					} else {
						this.form.uid = row.uid;
						this.form.customerEnable = true;
						this.getupdateCustomerCustomize();
					}
				}
			},
			getupdateCustomerCustomize() {
				let data = {
					companyId: this.query.companyId,
					customerEnable: this.form.customerEnable,
					uid: this.form.uid
				};
				updateCustomerCustomize(data).then(res => {
					if (res.code == 200) {
						this.$message.success('修改成功');
						this.getData();
						this.editVisible = false;
					} else {
						this.$message.error(res.message);
					}
				});
			},
			getData() {
				let data = {
					companyId: this.query.companyId
				};
				console.log(data, 'ddljdlj')
				getListCustomize(data).then(res => {
					if (res.code == 200) {
						this.tableData = res.data;
						this.tableData.forEach((i, index) => {
							if (i.options) {
								this.tableData[index].options = Array.from(i.options, ({
									str
								}) => str);
							}
						});
					} else {
						this.$message.error(res.message);
					}
				});
			},
			showForm() {
				if (this.query.companyId) {
					this.addVisible = true;
				} else {
					this.$message.error('请选择企业');
				}
			},

			// 点击确定上传信息
			addCustomize(formName) {
				this.$refs[formName].validate(valid => {
					if (valid) {
						let data = {
							companyId: this.query.companyId,
							label: this.form.label,
							type: this.form.chineseType,
							options: this.form.options
						};
						addCustomize(JSON.stringify(data)).then(res => {
							if (res.code == 200) {
								this.$message.success(res.data);
								this.addVisible = false;
								this.getData();
							} else {
								this.$message.error(res.message);
								this.getData();
							}
						});
					}
				});
			},

			// 编辑操作
			handleEdit(index, row) {
				this.editVisible = true;
				this.oldLabel = row.label;
				this.form = row;
			},
			handleEdit2(index, row) {
				this.editVisible2 = true;
				this.form = row;
			},
			// 点击修改信息
			updateCustomizeName(formName) {
				this.$refs[formName].validate(valid => {
					if (valid) {
						let data = {
							uid: this.form.uid,
							label: this.form.label,
							companyId: this.query.companyId
						}
						updateCustomizeName(data).then(res => {
							if (res.code == 200) {
								this.$message.success(res.message);
								this.getData();
								this.editVisible = false;
							} else {
								this.$message.error(res.message);
							}
						});
					}
				});
			},
			updateCustomizeContent(formName) {
				this.$refs[formName].validate(valid => {
					if (valid) {

						let data = {
							uid: this.form.uid,
							companyId: this.query.companyId,
							options: this.form.options
						}

						updateCustomizeContent(JSON.stringify(data)).then(res => {
							if (res.code == 200) {
								this.$message.success(res.message);
								this.getData();
								this.editVisible2 = false;
							} else {
								this.$message.error(res.message);
							}
						});
					}
				});
			},
			// 触发搜索按钮
			handleSearch() {
				this.query.pageIndex = 1;
				this.getData();
			},
			// 删除操作
			handleDelete(index, row) {
				// 二次确认删除
				this.$confirm('确定要删除吗？', '提示', {
						type: 'warning'
					})
					.then(() => {
						let data = {
							uid: row.uid,
							companyId: this.query.companyId
						};
						deleteCustomize(data).then(res => {
							if (res.code == 200) {
								this.$message.success('删除成功');
								this.pageTotal = this.pageTotal - 1;
								this.getData();
							} else {
								this.$message.error(res.message);
							}
						});
					})
					.catch(() => {});
			},
			// 分页导航
			handlePageChange(val) {
				this.query.pageIndex = val;
				this.getData();
			},

			// 点击取消
			closeDialog(formName) {
				this.form = {
					chineseType: '',
					label: '',
					options: [],
					companyId: ''
				};
				this.addVisible = false;
				this.editVisible = false;
				this.editVisible2 = false;
				this.getData();
			}
		}
	};
</script>

<style scoped>
	.handle-box {
		margin-bottom: 20px;
	}

	/* a:hover {
	background: #66b1ff;
} */
	.handle-select {
		width: 240px;
	}

	.table {
		width: 100%;
		font-size: 12px;
	}

	.red {
		color: #f56c6c;
	}

	.mr10 {
		margin-right: 10px;
	}

	.mr100 {
		position: absolute;
		right: 100px;
	}

	.myRight {
		position: absolute;
		right: 10px;
		bottom: 10px;
	}

	.handle-input {
		width: 200px;
		display: inline-block;
	}

	.el-form-item__label {
		font-size: 13px !important;
	}

	.hide .el-upload--picture-card {
		display: none;
	}

	.content_top {
		display: flex;
		justify-content: space-around;
	}

	.content_bottom {
		display: flex;
		justify-content: space-around;
	}

	.content_middle {
		display: flex;
		margin: 0.625rem 0 0.625rem 0;
		/* margin-bottom: 0.625rem; */
		justify-content: space-around;
	}

	.ywlx:hover {
		color: #00a854;
		background: none;
	}

	.content_top {
		display: flex;
		margin: auto;
	}
</style>